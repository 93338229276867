import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles((theme: ExtTheme) => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.px2rem(15)} ${theme.px2rem(10)}`,
      alignItems: 'center',
      [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
        width: '100%',
        boxShadow: 'none',
      },
      '& > svg': {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: theme.px2rem(250),
        },
      },
    },
    textWrapper: {
      marginTop: theme.px2rem(20),
      [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
        marginTop: theme.px2rem(15),
      },
    },
    errorMessage: {
      fontWeight: 500,
      color: theme.colors.onevisionBlack80,
      textAlign: 'center',
    },
    errorMessageSecondary: {
      color: theme.colors.onevisionBlack50,
      textAlign: 'center',
    },
  };
});
