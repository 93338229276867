import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { snackbarSlice } from './slices/snackbar.redux';

const rootReducer = combineReducers({
  snackbar: snackbarSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type PpStore = ReturnType<typeof store.getState>;

export const actions = {
  ...snackbarSlice.actions,
};

export const usePpSelector: TypedUseSelectorHook<PpStore> = useSelector;
