import React from 'react';
import { Typography, Box } from '@mui/material';
import { Pp404ErrorIcon } from './pp-404-error-icon';
import { useStyles } from './pp-404-page.styles';

export const Pp404Page: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Pp404ErrorIcon />
      <Box className={classes.textWrapper}>
        <Typography
          variant="h4"
          className={classes.errorMessage}
          role="error-message"
        >
          Page Not Found
        </Typography>
        <Typography className={classes.errorMessageSecondary}>
          Please contact us for more information
        </Typography>
      </Box>
    </Box>
  );
};
