import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SnackbarSliceState {
  text: string;
  type?: 'success' | 'info' | 'warning' | 'error';
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    text: '',
    type: undefined,
  } as SnackbarSliceState,
  reducers: {
    updateSnackbar: (_, action: PayloadAction<SnackbarSliceState>) =>
      action.payload,
    closeSnackbar: (state) => {
      state.text = '';
    },
  },
});
