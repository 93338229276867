import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { logRender, csn } from '@one-vision/utils';
import { PpLazyLoadImageView } from 'components/pp-lazy-load-image';
import { BackgroundInit } from 'assets';
import { thumb } from 'core/constants';
import { Content } from 'components/pp-content';
import { PpSnackbar } from 'components/pp-snackbar';
import { Pp404Page } from 'components/pp-404-page';
import { useStyles } from './pp-app.styles';

const win = window as Window &
  typeof globalThis & {
    ovPaymentPortalInitializationPromise: Promise<boolean>;
  };

export const PpApp: React.FC = () => {
  logRender(PpApp);

  const classes = useStyles();
  const [needToRenderContent, setNeedToRenderContent] = useState(false);
  const [showError, setShowError] = useState(false);

  win.ovPaymentPortalInitializationPromise
    .catch(() => setShowError(true))
    .finally(() => {
      setNeedToRenderContent(true);
    });

  return needToRenderContent ? (
    <Box className={classes.root}>
      <PpLazyLoadImageView
        className={classes.bgImage}
        src={BackgroundInit}
        thumbnail={thumb}
      />
      <PpSnackbar />
      <Box className={classes.contentWrapper}>
        <Box
          className={csn(classes.content, [
            classes.errorContent,
            showError,
          ])}
        >
          {showError ? <Pp404Page /> : <Content />}
        </Box>
      </Box>
    </Box>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  );
};
