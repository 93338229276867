import axios from 'axios';
import { MapLike } from 'typescript';
import { PaymentPortalUrl, PaymentPortalEmails } from './types';
import { afConfig } from './config';

const [_, token = ''] = new URL(document.URL).pathname.split('/');

export class OvAPI {
  private baseUrl = afConfig.api;

  private formHeaders = async () => {
    const headers: MapLike<string | number> = {
      'content-type': 'application/json',
      Authorization: token,
    };

    return headers;
  };

  private baseGet = async <T>(
    path: string,
    params?: MapLike<string | number | boolean>,
  ) => {
    return await axios.get<T>(`${this.baseUrl}/${path}`, {
      headers: await this.formHeaders(),
      params,
    });
  };

  private basePost = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${path}`;

    const response = await axios.post<T>(fullPath, payload, {
      headers: await this.formHeaders(),
      params,
    });

    return response;
  };

  public getPaymentPortalUrl = async () =>
    this.baseGet<PaymentPortalUrl>('payment-portal-url');

  public postPaymentPortalUrl = async (payload: { code: string }) =>
    this.basePost<PaymentPortalUrl>('payment-portal-url', payload);

  public postSendEmailWithCode = async (params: { emailId: string }) =>
    this.basePost('payment-portal-send-email-with-code', {
      context: { emailId: params.emailId },
    });

  public getEmails = async () =>
    this.baseGet<PaymentPortalEmails>('payment-portal-emails');
}

export const API = new OvAPI();
