import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { store } from 'core/redux';
import { PpApp } from 'components/pp-app/pp-app.view';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { theme } from 'core/theme';
import './assets';

const container = document.getElementById('root');
const root = createRoot(container as HTMLDivElement);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PpApp />
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
);
