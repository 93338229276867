import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      sendCodeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      icon: {
        color: '#FFB402',
        height: theme.px2rem(146),
        width: theme.px2rem(117),
        marginBottom: theme.px2rem(30),
      },
      header: {
        color: theme.colors.black,
        fontSize: theme.px2rem(27),
        fontWeight: 700,
        marginBottom: theme.px2rem(20),
      },
      text: {
        textAlign: 'center',
        color: theme.colors.onevisionBlack65,
        fontSize: theme.px2rem(16),
        fontWeight: 400,
      },
      textBold: {
        fontWeight: 500,
      },
      autocompleteWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.px2rem(10),
      },
      autocomplete: {
        width: '50%',
      },
      button: {
        marginTop: theme.px2rem(30),
        width: '60%',
        backgroundColor: theme.colors.secondary,
        color: theme.colors.white,
        '&:hover': {
          filter: theme.brightness.brightness90,
          backgroundColor: theme.colors.secondary,
        },
      },
      codeInputBlock: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.px2rem(30),
        justifyContent: 'center',
        alignItems: 'center',
        '& input': {
          border: 'none !important',
        },
      },
      inputCharacter: {
        border: 'none',
        borderBottom: `2px solid ${theme.colors.onevisionBlack65}`,
      },
      characterInactive: {
        backgroundColor: 'transparent',
      },
      characterSelected: {
        borderRadius: '3px',
      },
      errorWrap: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.px2rem(5),
      },
      errorIcon: {
        color: '#E53935',
        marginRight: theme.px2rem(10),
      },
      codeTextBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.px2rem(30),
      },
      codeText: {
        color: theme.colors.black,
        fontSize: theme.px2rem(14),
        fontWeight: 400,
      },
      resendButton: {
        color: theme.colors.secondary,
        padding: 0,
        textDecoration: 'underline',
        marginLeft: theme.px2rem(5),
      },
      resendWrap: {
        display: 'flex',
        flexDirection: 'row',
      },
    };
  },
  { name: 'pp-send-code-step' },
);
