import { createTheme, Theme, ThemeOptions } from '@mui/material';

export const themeCustomParams = {
  /*
      @base should be taken from `htmlFontSize` if treated as root rem font-size
    */
  px2rem: (px: number, htmlFontSize = 14) => {
    const rem = (px / htmlFontSize).toFixed(2);

    return `${rem}rem`;
  },
  af: {
    breakpoints: {
      sm: 768,
    },
  },
  brightness: {
    brightness90: 'brightness(90%)',
  },
  colors: {
    black: '#000000',
    white: '#fff',
    onevisionBlack: '#1F2124',
    onevisionBlack20: '#D2D3D3',
    onevisionBlack65: '#6D6F71',
    onevisionBlack50: '#8F9091',
    onevisionBlack80: '#4C4D50',
    primary: '#E9840E',
    secondary: '#2196F3',
  },
};

export type ExtTheme = Theme & typeof themeCustomParams;

export const standardParams = {
  palette: {
    primary: {
      light: '#54d0d3',
      main: '#2AC5C9',
      dark: '#1d898c',
    },
    secondary: {
      light: '#b47aff',
      main: '#A259FF',
      dark: '#713eb2',
    },
    divider: '#E5E5E5',
    background: {
      default: '#FBFCFD',
    },
  },
  typography: {
    useNextVariants: true,
  },
};

export const theme = createTheme({
  ...standardParams,
  ...themeCustomParams,
} as ThemeOptions);
