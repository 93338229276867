import React from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { actions, usePpSelector } from '../../core/redux';

export const PpSnackbar: React.FC = () => {
  const dispatch = useDispatch();
  const { text, type } = usePpSelector((state) => state.snackbar);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(actions.closeSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(text)}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={type}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};
