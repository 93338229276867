import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VerificationInput from 'react-verification-input';
import { actions } from 'core/redux';
import { API } from 'core/api';
import { localStorageSetter, localStorageGetter } from 'core/utils';
import { CODE_NAME } from 'core/constants';
import { EmailItem } from 'core/types';
import { useStyles } from './pp-content.styles';

const CODE_LENGTH = 6;

enum Step {
  SendCode,
  SubmitCode,
}

const SNACKBAR_TEXT = 'A verification code has been sent to';

export const Content: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const ref = useRef<HTMLInputElement | null>(null);

  const [step, setStep] = useState(
    localStorageGetter(CODE_NAME) ? Step.SubmitCode : Step.SendCode,
  );
  const [code, setCode] = useState<string>();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState<EmailItem[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<EmailItem | null>(
    null,
  );

  useEffect(() => {
    setIsLoading(true);
    API.getEmails()
      .then(({ data: { data } }) => {
        setEmails(data.emails);
        if (data.emails.length) {
          setSelectedEmail(data.emails[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (code?.length === CODE_LENGTH && ref.current) {
      ref.current.blur();
    }
  }, [code]);

  const autocompleteHandler = useCallback(
    (_: React.SyntheticEvent, newValue: EmailItem | null) => {
      setSelectedEmail(newValue);
    },
    [setSelectedEmail],
  );

  const submitCodeStep = useCallback(() => {
    if (selectedEmail) {
      API.postSendEmailWithCode({ emailId: selectedEmail.id });
      dispatch(
        actions.updateSnackbar({
          text: `${SNACKBAR_TEXT} ${selectedEmail.email}`,
          type: 'info',
        }),
      );
      setStep(Step.SubmitCode);
    }
  }, [step, selectedEmail]);

  const resendHandler = useCallback(() => {
    if (selectedEmail) {
      dispatch(
        actions.updateSnackbar({
          text: `${SNACKBAR_TEXT} ${selectedEmail.email}`,
          type: 'info',
        }),
      );
      API.postSendEmailWithCode({ emailId: selectedEmail.id });
    }
  }, [selectedEmail]);

  const submitHandler = useCallback(() => {
    if (code) {
      setIsLoading(true);
      API.postPaymentPortalUrl({ code })
        .then(({ data }) => {
          const url = data?.data?.url;
          if (!url) {
            throw new Error(
              'API.postPaymentPortalUrl: Url is missing from the response',
            );
          }
          localStorageSetter(CODE_NAME, code);
          window.location.replace(url);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [code, isLoading]);

  const inputHandler = useCallback(
    (e: string) => {
      if (error) {
        setError(false);
      }
      setCode(e);
    },
    [code, error],
  );

  return (
    <Box className={classes.sendCodeWrapper}>
      <LocalPoliceOutlinedIcon className={classes.icon} />
      <Typography className={classes.header}>
        Let’s confirm your identity.
      </Typography>
      <Typography className={classes.text}>
        Protecting your personal information is our top priority.
      </Typography>
      <Typography className={classes.text}>
        Please click below and we will send a verification code to your
        email:
      </Typography>
      {step === Step.SendCode && (
        <Box className={classes.autocompleteWrapper}>
          <Autocomplete
            className={classes.autocomplete}
            value={selectedEmail}
            options={emails}
            renderInput={(params) => (
              <TextField {...params} label="Email" />
            )}
            getOptionLabel={(option) => option.email}
            onChange={autocompleteHandler}
            loading={isLoading}
            clearIcon={null}
          />
        </Box>
      )}
      {step === Step.SendCode && (
        <Button
          disabled={!selectedEmail}
          onClick={submitCodeStep}
          className={classes.button}
        >
          Send code
        </Button>
      )}
      {step === Step.SubmitCode && (
        <Box className={classes.codeInputBlock}>
          <Box>
            <VerificationInput
              ref={ref}
              length={CODE_LENGTH}
              validChars="0-9"
              placeholder=""
              classNames={{
                character: classes.inputCharacter,
                characterInactive: classes.characterInactive,
                characterSelected: classes.characterSelected,
              }}
              value={code}
              onChange={inputHandler}
            />
            {error && (
              <Box className={classes.errorWrap}>
                <ErrorOutlineIcon className={classes.errorIcon} />
                <FormHelperText error>
                  Incorrect verification code.
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Box className={classes.codeTextBlock}>
            <Typography className={classes.codeText}>
              It may take a minute to receive your code.
            </Typography>
            <Box className={classes.resendWrap}>
              <Typography className={classes.codeText}>
                Haven’t received your code?
              </Typography>
              <Button
                className={classes.resendButton}
                onClick={resendHandler}
              >
                Resend it
              </Button>
            </Box>
          </Box>
          <Button
            className={classes.button}
            disabled={code?.length !== CODE_LENGTH || isLoading}
            onClick={submitHandler}
          >
            {isLoading ? 'Loading' : 'Submit'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
