type OvReactAppStage = 'prod' | 'dev' | 'local';

export const afConfig = {
  stage: process.env.REACT_APP_STAGE as OvReactAppStage,
  api: process.env.REACT_APP_API_URL as string,
  errorsApi: process.env.REACT_APP_ERRORS_API_URL as string,
};

if (afConfig.stage !== 'prod') {
  console.log(`stage: ${afConfig.stage?.toUpperCase()}`);
}
